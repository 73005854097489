import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';

// initialize Google Analytics
const TRACKING_ID = "UA-220757500-1";
ReactGA.initialize(TRACKING_ID, {gaAddress: "/utils/ga/analytics.js"});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

const gaPerf = ({ id, name, value }) => {
    ReactGA.event({
        action: name,
        category: 'Web Vitals',
        label: id,
        nonInteraction: true,
        value: name === 'CLS' ? value * 1000 : value
    });
}

reportWebVitals(gaPerf);
