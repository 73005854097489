import { NavLink } from "react-router-dom";
import ReactGA from "react-ga";

function Footer() {
    const sayThanks = () => {
        setTimeout(() => {
            window.location.href = "/bmcthx";
        }, 500);
    }

    return (
        <footer className="footer">
            <div className="content has-text-centered">
                <p>
                    <strong>Scroller Note</strong> family of apps by Matt Bielich:
                </p>
                <div className="tags is-centered notranslate">
                    <div className="field mb-2">
                        <NavLink className="tags has-addons" to="applications/note">
                            <span className="tag is-dark icon">
                                <i className="fas fa-comment"></i>
                            </span>
                            <span className="tag is-primary is-dark has-text-dark">Scroller Note</span>
                        </NavLink>
                    </div>
                    <div className="field mb-2">
                        <NavLink className="tags has-addons" to="applications/fitness">
                            <span className="tag is-dark icon">
                                <i className="fas fa-heartbeat"></i>
                            </span>
                            <span className="tag is-primary is-dark has-text-dark">Fitness Tracker</span>
                        </NavLink>
                    </div>
                    <div className="field mb-2">
                        <NavLink className="tags has-addons" to="applications/calendar">
                            <span className="tag is-dark icon">
                                <i className="fas fa-calendar"></i>
                            </span>
                            <span className="tag is-primary is-dark has-text-dark">Calendar Viewer</span>
                        </NavLink>
                    </div>
                    <div className="field mb-2">
                        <NavLink className="tags has-addons" to="applications/inspire">
                            <span className="tag is-dark icon">
                                <i className="fas fa-lightbulb"></i>
                            </span>
                            <span className="tag is-primary is-dark has-text-dark">Inspire GPT</span>
                        </NavLink>
                    </div>
                    <div className="field mb-2">
                        <NavLink className="tags has-addons" to="applications/joke">
                            <span className="tag is-dark icon">
                                <i className="fas fa-face-grin-squint-tears"></i>
                            </span>
                            <span className="tag is-primary is-dark has-text-dark">Joke GPT</span>
                        </NavLink>
                    </div>
                    <div className="field mb-2">
                        <NavLink className="tags has-addons" to="applications/deepthoughts">
                            <span className="tag is-dark icon">
                                <i className="fas fa-feather-pointed"></i>
                            </span>
                            <span className="tag is-primary is-dark has-text-dark">Deep Thoughts GPT</span>
                        </NavLink>
                    </div>
                    <div className="field mb-2">
                        <NavLink className="tags has-addons" to="applications/woot">
                            <span className="tag is-dark icon">
                                <i className="fas fa-circle-exclamation"></i>
                            </span>
                            <span className="tag is-primary is-dark has-text-dark">Woot! Daily Deals</span>
                        </NavLink>
                    </div>
                    <div className="field mb-2">
                        <NavLink className="tags has-addons" to="applications/meh">
                            <span className="tag is-dark icon">
                                <i className="fas fa-face-meh"></i>
                            </span>
                            <span className="tag is-primary is-dark has-text-dark">Meh Daily Deals</span>
                        </NavLink>
                    </div>
                    <div className="field mb-2">
                        <ReactGA.OutboundLink
                            className="tags has-addons"
                            eventLabel="BMC-footer"
                            to="https://www.buymeacoffee.com/mbielich"
                            target="_blank"
                            onClick={sayThanks}>
                            <span className="tag is-dark icon">
                                <i className="fas fa-coffee"></i>
                            </span>
                            <span className="tag is-warning is-dark has-text-dark">Buy me a coffee to fuel my work</span>
                        </ReactGA.OutboundLink>
                    </div>
                </div>
                <p>
                    <i>Please see our 
                    <ReactGA.OutboundLink
                        className="space-both"
                        eventLabel="Privacy Policy"
                        to="/privacy.html"
                        target="_blank">
                        Privacy Policy
                    </ReactGA.OutboundLink>
                    and 
                     <ReactGA.OutboundLink
                        className="space-both"
                        eventLabel="Terms and Conditions"
                        to="/terms.html"
                        target="_blank">
                        Terms and Conditions
                    </ReactGA.OutboundLink>
                    for details.</i>
                </p>
                <div className="content">
                <p className="title is-7 mb-5 has-text-dark">Notice of Non-Affiliation and Disclaimer:</p>
                <p className="subtitle title is-7 mb-5 has-text-dark">
                    We are not affiliated, associated, authorized, endorsed by, or in any way officially connected with LaMetric, Smart Atoms, or any of its subsidiaries or its affiliates.
                    The official LaMetric website can be found at
                    <ReactGA.OutboundLink
                        className="space-left"
                        eventLabel="LaMetric Website"
                        to="https://lametric.com"
                        target="_blank">
                        https://lametric.com
                    </ReactGA.OutboundLink>.
                </p>
                <p className="subtitle title is-7 has-text-dark">
                    LaMetric as well as related names, marks, emblems and images are registered trademarks of their respective owners.
                </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
