import ReactGA from 'react-ga';

function FAQ(props) {
    let categories = props.data;
    return (
        <div className="details-section white-bg">
            <p className="cap-width">
                <i>
                    If you have specific questions about our apps, check the common answers below.
                    For help with your LaMetric Time device, see the
                    <ReactGA.OutboundLink
                        className="space-left"
                        eventLabel="LaMetric Users Guide"
                        to="http://lametric.com/user_guide.pdf"
                        target="_blank">
                        LaMetric Users Guide
                    </ReactGA.OutboundLink>.
                </i>
            </p>
            {categories.map((group) => {
                return (
                    <section className="section has-text-left" key={`faq-category-${group.category}`}>
                        <p className="title is-3 is-info has-text-primary is-underlined has-text-weight-super-bold mb-6">{group.category}</p>
                        <div>
                            {group.items.map((item, index) => {
                                return (
                                    <div className="block" key={`faq-category-${group.category}-${index}`}>
                                        <p className="block">
                                            <span className="icon-text fa-2x">
                                                <span className="icon fa-layers has-text-primary">
                                                    <i className="fas fa-comment fa-flip-horizontal"></i>
                                                    <i className="fa-inverse fas fa-q" data-fa-transform="shrink-7 right-1 up-1"></i>
                                                </span>
                                                <span>&nbsp;</span>
                                            </span>
                                            <span className="title is-5">{item.question}</span>
                                        </p>
                                        <p>
                                            <span className="icon-text fa-2x">
                                                <span className="icon fa-layers has-text-primary">
                                                    <i className="fas fa-comment"></i>
                                                    <i className="fa-inverse fas fa-a" data-fa-transform="shrink-7 right-2 up-1"></i>
                                                </span>
                                                <span>&nbsp;</span>
                                            </span>
                                            <span className="subtitle is-5">{item.answer}</span>
                                        </p>
                                        <hr />
                                    </div>
                                );
                            })}
                        </div>
                    </section>
                );
            })}
        </div>
    );
}

export default FAQ;
