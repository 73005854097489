import React from "react";
import Display, { SCROLL_FRAME_END_EVENT } from "./Display";

// assets
import './Device.css';
import svgBaseDevice from "./device.svg";
import svgPixelMask from "./pixel-mask.svg";

class Device extends React.Component {
    constructor(props) {
        super(props);

        let currentFrame = this.props.frames?.length > 0 ? 0 : -1;

        this.state = {
            currentFrame
        };

        this.showNextFrame = this.showNextFrame.bind(this);
    }

    componentDidMount() {
        document.addEventListener(SCROLL_FRAME_END_EVENT, this.showNextFrame);
    }
  
    componentWillUnmount() {
        document.removeEventListener(SCROLL_FRAME_END_EVENT, this.showNextFrame);
    }

    showNextFrame() {
        if (this.props.frames?.length > 0) {
            let currentFrame = (this.state.currentFrame + 1) % this.props.frames.length;
            this.setState({ currentFrame });
        } else {
            this.setState({ currentFrame: -1 });
        }
    }

    render() {
        return (
            <div className="device" role="marquee" aria-label={(this.state.currentFrame >= 0) ? this.props.frames[this.state.currentFrame].text : ""}>
                <img src={svgBaseDevice} className="core" alt="LaMetric Time Device" />
                <Display frame={(this.state.currentFrame >= 0) ? this.props.frames[this.state.currentFrame] : {} } />
                <img src={svgPixelMask} className="frame" alt="" />
            </div>
        );
    }
}

export default Device;
