import { useEffect, useState } from "react";
const axios = require("axios");

function KnownIssues(props) {
    let tag = props.tag;
    const [loading, setLoading] = useState("loading");
    const [issues, setIssues] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);

    useEffect(() => {
        if (fetchedData) { return true; }
        setFetchedData(true);

        axios.get("/jira/knownissues", {
            params: {
                family_id: tag
            }
        })
        .then((response) => {
            setIssues(response.data?.issues || []);
            setLoading("done");
        })
        .catch((error) => {
            setIssues([]);
            setLoading("error");
        });
    }, [tag, fetchedData]);

    return(
        <div className="details-section white-bg">
            <p className="cap-width">
                <i>If you encounter an issue not listed below, let us know by emailing <a href="mailto:lametricapps@mattbielich.com">lametricapps@mattbielich.com</a>.</i>
            </p>
            <section className="section">
                {loading === "loading" ? (
                    <div key="loading-panel" className="panel-block hero text-center">
                        <div className="container mt-6">
                        <span className="fa-5x icon-text has-text-info">
                            <i className="fas fa-spinner fa-pulse"></i>
                        </span>
                        </div>
                    </div>
                ) : loading === "error" ? (
                    <div key="error-panel" className="panel-block hero text-center">
                        <div className="container mt-6">
                        <span className="fa-5x icon-text has-text-danger">
                            <i className="fas fa-exclamation-triangle"></i>
                        </span>
                        <p>Please try again later.</p>
                        </div>
                    </div>
                ) : issues.length === 0 ? (
                    <div className="panel-block text-center">
                        <div className="container">
                            <span className="icon-text">
                                <span className="icon has-text-success">
                                    <i className="fas fa-check-circle"></i>
                                </span>
                                <span>There are currently no known issues.</span>
                            </span>
                        </div>
                    </div>
                ) : (
                        <table className="table is-striped is-fullwidth no-collapse has-text-left">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Title</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {issues.map((issue) => (
                                    <tr key={issue.key}>
                                        <td className="no-wrap">
                                            <img src={issue.fields.issuetype.iconUrl} alt={issue.fields.issuetype.name} className="mr-1" />
                                            {issue.fields.issuetype.name}
                                        </td>
                                        <td>{issue.fields.summary}</td>
                                        <td className="has-text-right"><span className={`tag ${issue.fields.status.statusCategory.colorName === 'yellow' ? 'is-warning' : issue.fields.status.statusCategory.colorName === 'green' ? 'is-success' : 'is-info'}`}>{issue.fields.status.name}</span></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                )}
            </section>
        </div>
    );
}

export default KnownIssues;
