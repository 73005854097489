import React from "react";
import LED_FONT from "./LEDFont";
import LED_ICON from "./LEDIcons";

export const SCROLL_FRAME_END_EVENT = "animationScrollFrameEnded";
export const ROWS = 8;
export const COLS = 37;
const ANIMATION_SPEED = 30;
const DROP_PAUSE = 35;
const SCROLL_PAUSE = 1;
const PIXEL_ON = "#f0f0f0";
const PIXEL_DIM = "#808080";

class Display extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pixels: [],
            viewport: [],
            animate: {
                x: 0,
                y: 0,
            }
        };
    }

    setupAnimation() {
        let rows = new Array(ROWS);
        for (let i = 0; i < rows.length; i++) {
            rows[i] = new Array(COLS);
            rows[i].fill("");
        }

        let hasIcon = !!this.props.frame?.icon;
        let hasText = !!this.props.frame?.text;
        let hasProgress = !!this.props.frame?.progress;
        let maxVisibleWidth = hasIcon ? COLS - 9 : COLS - 1;
        let pos = 0;

        if (hasText) {
            // initial text offset
            pos += hasIcon ? 9 : 1;
            for (let i = 0; i < this.props.frame.text.length; i++) {
                let char = this.props.frame.text[i];
                let charData = LED_FONT[char];
                let charWidth = charData.length / ROWS;
                for (let x = 0; x < charData.length; x++) {
                    let row = Math.trunc(x / charWidth);
                    let col = pos + (x % charWidth);
                    rows[row][col] = (charData[x] === 1) ? PIXEL_ON : "";
                }
                pos += charWidth;
            }
            pos -= hasIcon ? 9 : 1;
        }

        if (pos < maxVisibleWidth) {
            let offset = Math.trunc((maxVisibleWidth - pos) / 2);
            let offsetVals = new Array(offset);
            offsetVals.fill("");
            rows.forEach((row) => {
                row.unshift(...offsetVals);
                row.splice(COLS);
            });
        }

        if (hasIcon) {
            let iconData = LED_ICON[this.props.frame.icon];
            let iconWidth = iconData.length / ROWS;
            for (let x = 0; x < iconData.length; x++) {
                let row = Math.trunc(x / iconWidth);
                let col = x % iconWidth;
                rows[row][col] = (iconData[x] !== 0) ? iconData[x] : "";
            }
        }

        if (hasProgress) {
            maxVisibleWidth = hasIcon ? COLS - 9 : COLS;
            let prog = Math.min(1, Math.max(0, this.props.frame.progress));
            let progWidth = Math.round(prog * maxVisibleWidth);
            for (let i = hasIcon ? 9 : 0; i < COLS; i++) {
                rows[ROWS-1][i] = progWidth-- > 0 ? PIXEL_ON : PIXEL_DIM;
            }
        }

        this.setState({
            pixels: rows,
            viewport: [],
            animate: {
                x: 0,
                y: 0,
            }
        });

        if (!this.timerID) {
            this.timerID = setInterval(
                () => this.animationTick(),
                ANIMATION_SPEED
            );
        }
    }

    componentDidMount() {
        // check if we have something to populate
        this.setupAnimation();
    }
  
    componentDidUpdate(prevProps) {
        if (prevProps.frame !== this.props.frame) {
            this.setupAnimation();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    animationTick() {
        let animate = {
            x: this.state.animate.x,
            y: this.state.animate.y,
            xPause: this.state.animate.xPause,
            yPause: this.state.animate.yPause
        }
        let lineLength = this.state.pixels[0].length;
        if (animate.y < ROWS) {
            // animate the frame down
            animate.y++;
            animate.yPause = DROP_PAUSE;
            this.sliceViewport(ROWS - animate.y, ROWS);
        } else if (animate.yPause > 0) {
            animate.yPause--;
        } else if (lineLength <= COLS) {
            // frame fits fully, we're done animating
            this.signalFrameDone();
            return;
        } else if (animate.xPause > 0) {
            animate.xPause--;
        } else if (animate.x < lineLength) {
            // frame is too long to fit fully, scroll it
            animate.x++;
            animate.xPause = SCROLL_PAUSE;
            this.sliceViewport(0, ROWS, animate.x, Math.min(animate.x + COLS, lineLength));
        } else {
            // scrolling animation is done
            this.signalFrameDone();
            return;
        }

        this.setState({ animate });
    }

    signalFrameDone() {
        clearInterval(this.timerID);
        this.timerID = null;
        document.dispatchEvent(new Event(SCROLL_FRAME_END_EVENT));
    }

    sliceViewport(rowFrom = 0, rowTo = ROWS, colFrom = 0, colTo = COLS) {
        let colFill = (colTo - colFrom < COLS) ? new Array(COLS - (colTo - colFrom)).fill("") : [];
        let rows = this.state.pixels.slice(rowFrom, rowTo).map((row) => row.slice(colFrom, colTo).concat(colFill));
        while (rows.length < ROWS) {
            rows.push(new Array(COLS).fill(""));
        }
        let viewport = rows.flat();
        if (viewport.length !== ROWS * COLS) {
            throw new Error("Invalid viewport dimensions!");
        }
        this.setState({ viewport });
    }

    render() {
        return (
            <div className="display">
                {
                    this.state.viewport.map((pixel, index) => <div className="pixel" key={`pixel-${index}`} style={{backgroundColor: pixel}} />)
                }
            </div>
        );
    }
}

export default Display;
