import { NavLink } from "react-router-dom";
import ReactGA from "react-ga";

import Device from "./device/Device";

function Home() {
    const slugs = ["note", "fitness", "calendar", "inspire", "joke", "deepthoughts", "woot", "meh"];

    return (
        <div className="">
            <section className="section">
                <Device frames={[
                    { text: "HI!", icon: "smile" },
                    { text: "WE HAVE" },
                    { text: "LAMETRIC APPS" },
                    { text: "FOR YOU!" },
                    { text: "SCROLLER NOTE", icon: "scrollernote" },
                    { text: "CALENDAR VIEWER", icon: "calendar-google" },
                    { text: "FITNESS TRACKER", icon: "fitbit" },
                    { text: "INSPIRE GPT", icon: "chatgpt" },
                    { text: "JOKE GPT", icon: "chatgpt" },
                    { text: "DEEP THOUGHTS GPT", icon: "chatgpt" },
                    { text: "WOOT! DAILY DEALS", icon: "woot-logo" },
                    { text: "MEH DAILY DEALS", icon: "meh-logo" },
                ]} />
            </section>
            <section className="section">
                <h1 className="title is-3 has-text-dark">Check out our LaMetric apps:</h1>
                <p className="block">Look for these apps in the LaMetric Market when configuring your LaMetric Time device.  If you don't already have one, you 
                <ReactGA.OutboundLink
                    className="space-left"
                    eventLabel="LaMetric Store"
                    to="https://store.lametric.com"
                    target="_blank">
                    should pick one up today
                </ReactGA.OutboundLink>!</p>
                <div className="columns is-multiline">
                    { slugs.map((slug) => {
                        let appData = require(`./apps/data/${slug}.json`);
                        let logo = require(`../resources/${appData.icon}`);    
                
                        return (
                            <NavLink
                                to={`applications/${slug}`}
                                key={`tile-${slug}`}
                                className="column is-one-third tile is-parent"
                                aria-label={`Link to ${appData.name}`}>
                                <article className="tile is-child box">
                                    <div className="columns is-mobile">
                                        <div className="column is-one-third">
                                            <img src={logo} alt="app icon" />
                                        </div>
                                        <div className="column">
                                            <p className="title">
                                                {appData.name}
                                            </p>
                                        </div>
                                    </div>
                                    <p className="subtitle">
                                        {appData.shortDescription}
                                    </p>
                                </article>
                            </NavLink>
                        )
                    }) }
                </div>
            </section>
        </div>
    );
}

export default Home;
