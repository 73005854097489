import { useParams, useLocation } from "react-router";
import DetailsTabs from "./DetailsTabs";
import Device from "../device/Device";
import ReactGA from 'react-ga';

function LaMetricApp() {
    let { appSlug } = useParams();
    let { hash } = useLocation();
    let tabSlug = hash.replace("#", "");
    let appData = require(`./data/${appSlug}.json`);
    let logo = require(`../../resources/${appData.icon}`);

    return (
        <div>
            <section className="section">
                <div className="card">
                    <div className="card-content">
                        <div className="columns is-vcentered">
                            <div className="column">
                                <img src={logo} alt="app icon" />
                                <p className="title">
                                    {appData.name}
                                </p>
                                <p className="subtitle">
                                    by {appData.author}
                                </p>
                            </div>
                            <div className="divider is-vertical is-hidden-mobile" />
                            <div className="divider is-hidden-tablet" />
                            <div className="column">
                                <p className="block cap-width">
                                    {appData.description}
                                </p>
                                {appData.sample?.length > 0 && <Device frames={appData.sample} />}
                            </div>
                        </div>
                    </div>
                    <footer className="card-footer">
                        <ReactGA.OutboundLink
                            eventLabel={`${appSlug} details in LaMetric Market`}
                            to={appData.externalLink}
                            target="_blank"
                            className="card-footer-item">
                            <span className="icon">
                                <i className="fas fa-link"></i>
                            </span>
                            <span>See details on apps.lametric.com</span>
                        </ReactGA.OutboundLink>
                    </footer>
                </div>
            </section>
            <DetailsTabs data={appData} tabSlug={tabSlug} />
        </div>
    );
}

export default LaMetricApp;
