import Device from "./device/Device";

function BuyMeCoffee() {
    return (
        <div className="">
            <section className="section">
                <Device frames={[
                    { text: "THANKS", icon: "heart" },
                    { text: "FOR MY", icon: "smile" },
                    { text: "COFFEE", icon: "coffee" },
                    { text: "AND FUELING MY APPS", icon: "battery"},
                    { text: "YOU ARE THE BEST!", icon: "like"},
                ]} />
            </section>
            <section className="section">
                <h1 className="title is-3 has-text-dark">Thank you!!</h1>
                <p className="block">
                    I enjoy making apps and plugins as a hobby and love to try and make cool things for others to enjoy - thanks for supporting my projects!
                </p>
            </section>
        </div>
    );
}

export default BuyMeCoffee;
