import ReactGA from "react-ga";

function DataTransparency(props) {
    let data = props.data;
    let usageTenetsSet = new Set();
    data.usage.items.forEach((item) => {
        item.appliesTo.forEach((tenet) => usageTenetsSet.add(tenet));
    });
    let usageTenets = Array.from(usageTenetsSet.values());
    let storageTenetsSet = new Set();
    data.storage.items.forEach((item) => {
        item.appliesTo.forEach((tenet) => storageTenetsSet.add(tenet));
    });
    let storageTenets = Array.from(storageTenetsSet.values());

    let checkmark = (<span className="icon has-text-primary"><i className="fas fa-check"></i></span>);

    return(
        <div className="details-section white-bg">
            <p className="cap-width">
                <i>We believe in being transparent about how your data is being used and stored in our apps - you'll find details below.  We do not share your data with third parties.  You're also welcome to visit our
                <ReactGA.OutboundLink
                    className="space-both"
                    eventLabel="Privacy Policy"
                    to="/privacy.html"
                    target="_blank">
                    Privacy Policy
                </ReactGA.OutboundLink>
                and
                <ReactGA.OutboundLink
                    className="space-both"
                    eventLabel="Terms and Conditions"
                    to="/terms.html"
                    target="_blank">
                    Terms and Conditions
                </ReactGA.OutboundLink>
                pages.</i>
            </p>
            <section className="section has-text-left">
                <p className="title is-3 is-info has-text-primary is-underlined has-text-weight-super-bold mb-6">Data Usage</p>
                <p className="subtitle is-5">{data.usage.description}</p>
                    { (data.usage.items.length) ?
                    <table className="table is-striped is-fullwidth has-text-left">
                        <thead>
                            <tr>
                                <th>What we use</th>
                                <th>Why</th>
                                {usageTenets.map((tenet) => <th key={`usage-${tenet}`}>{tenet}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {data.usage.items.map((item, index) => <tr key={`usage-row-${index}`}><td key={`usage-what-${index}`}>{item.what}</td><td key={`usage-why-${index}`}>{item.why}</td>{usageTenets.map((tenet) => <td key={`usage-${tenet}-${index}`}>{item.appliesTo.includes(tenet) ? checkmark : ""}</td>)}</tr>)}
                        </tbody>
                    </table>
                    : ""
                    }
            </section>
            <section className="section has-text-left">
            <p className="title is-3 is-info has-text-primary is-underlined has-text-weight-super-bold mb-6">Data Storage</p>
                <p className="subtitle is-5">{data.storage.description}</p>
                    <table className="table is-striped is-fullwidth has-text-left">
                        <thead>
                            <tr>
                                <th>What we store</th>
                                <th>Why</th>
                                {storageTenets.map((tenet) => <th key={`storage-${tenet}`}>{tenet}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {data.storage.items.map((item, index) => <tr key={`storage-row-${index}`}><td key={`storage-what-${index}`}>{item.what}</td><td key={`storage-why-${index}`}>{item.why}</td>{storageTenets.map((tenet) => <td key={`storage-${tenet}-${index}`}>{item.appliesTo.includes(tenet) ? checkmark : ""}</td>)}</tr>)}
                        </tbody>
                    </table>
            </section>
        </div>
    );
}

export default DataTransparency;
