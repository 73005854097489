var LED_ICON = {};

LED_ICON["fitbit"] = [
    0,0,0,"#00a8b0","#00a8b0",0,0,0,
    0,0,0,"#00a8b0","#00a8b0",0,0,0,
    0,0,"#00a8b0","#00a8b0","#00a8b0","#00c3cd",0,0,
    "#008b93","#008b93","#00a8b0","#00a8b0","#00c3cd","#00c3cd","#00c3cd","#00c3cd",
    "#008b93","#008b93","#008b93","#00a8b0","#00a8b0","#00c3cd","#00c3cd","#00c3cd",
    0,0,"#00a8b0","#00a8b0","#00c3cd","#00c3cd",0,0,
    0,0,0,"#00a8b0","#00a8b0",0,0,0,
    0,0,0,"#00a8b0","#00a8b0",0,0,0
];

LED_ICON["fitness-scale"] = [
    0,         '#22aa13', '#22aa13', '#888888', '#888888','#22aa13', '#22aa13', 0,
    '#22aa13', '#22aa13', '#ffffff', '#626060', '#626060', '#888888', '#22aa13', '#22aa13',
    '#22aa13', '#22aa13', '#626060', '#cccccc', '#6f6d6d', '#888888', '#22aa13', '#22aa13',
    '#22aa13', '#22aa13', '#22aa13', '#6f6d6d', '#6f6d6d', '#22aa13', '#22aa13', '#22aa13',
    '#22aa13', '#177700', '#177700', '#177700', '#136400', '#136400', '#136400', '#22aa13',
    '#22aa13', '#136400', '#136400', '#136400', '#135d01', '#135d01', '#135d01', '#22aa13',
    '#22aa13', '#136400', '#135d01', '#135d01', '#135d01', '#135d01', '#135d01', '#22aa13',
    0,         '#22aa13', '#22aa13', '#22aa13', '#22aa13', '#22aa13', '#22aa13', 0
];

LED_ICON["fitness-steps"] = [
    0,         0,         0,         0,         0,
    0,         0,         0,         0,         0,
    0,         0,         0,         0,         0,
    0,         0,         0,         0,         0,
    '#ffffff', '#ffffff', 0,         0,         '#c00000',
    '#f60017', '#f60017', '#ffffff', 0,         0,
    0,         0,         '#c00000', '#c00000', '#f60017',
    '#f60017', '#ffffff', 0,         0,         0,
    '#c00000', '#c00000', '#c00000', '#c00000', '#c00000',
    '#c00000', '#c00000', '#c00000', '#cccccc', '#cccccc',
    '#cccccc', '#cccccc', '#cccccc', '#cccccc', '#cccccc',
    '#cccccc', 0,         0,         0,         0,
    0,         0,         0,         0
];

LED_ICON["fitness-sleep"] = [
    0,         0,         0,         0,         0,
    '#f9ff1b', 0,         0,         0,         0,
    '#177700', '#22aa13', 0,         0,         0,
    0,         0,         '#177700', '#22aa13', 0,
    0,         0,         0,         '#f9ff1b', '#177700',
    '#22aa13', 0,         0,         0,         0,
    0,         0,         '#177700', '#22aa13', 0,
    0,         '#f9ff1b', 0,         0,         0,
    '#177700', '#22aa13', 0,         0,         0,
    0,         0,         0,         0,         '#177700',
    '#22aa13', 0,         0,         0,         '#f9ff1b',
    0,         0,         0,         '#177700', '#177700',
    '#177700', 0,         0,         0
];

LED_ICON["calendar"] = [
    '#f60000', '#f60000', '#f60000', '#f60000',
    '#f60000', '#f60000', '#f60000', '#f60000',
    '#d20000', '#d20000', '#d20000', '#d20000',
    '#d20000', '#d20000', '#d20000', '#d20000',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff'
];

LED_ICON["calendar-num-4"] = [
    '#ff0000', '#ff0000', '#ff0000', '#ff0000', '#ff0000',
    '#ff0000', '#ff0000', '#ff0000', '#ff0000', '#ff0000',
    '#ff0000', '#ff0000', '#ff0000', '#ff0000', '#ff0000',
    '#ff0000', '#ffffff', '#ffffff', '#ffffff', 0,
    '#ffffff', 0,         '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', 0,         '#ffffff', 0,
    '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    0,         0,         0,         '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    0,         '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', 0,         '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff'
];

LED_ICON["calendar-num-17"] = [
    '#ff0000', '#ff0000', '#ff0000', '#ff0000', '#ff0000',
    '#ff0000', '#ff0000', '#ff0000', '#ff0000', '#ff0000',
    '#ff0000', '#ff0000', '#ff0000', '#ff0000', '#ff0000',
    '#ff0000', '#ffffff', '#ffffff', 0,         '#ffffff',
    0,         0,         0,         '#ffffff', '#ffffff',
    0,         0,         '#ffffff', '#ffffff', '#ffffff',
    0,         '#ffffff', '#ffffff', '#ffffff', 0,
    '#ffffff', '#ffffff', 0,         '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', 0,         '#ffffff', '#ffffff',
    0,         '#ffffff', '#ffffff', '#ffffff', 0,
    0,         0,         '#ffffff', 0,         '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff'
];

LED_ICON["calendar-google"] = [
    '#f60000', '#f60000', '#f60000', '#f60000',
    '#f60000', '#f60000', '#f60000', '#f60000',
    '#d20000', '#d20000', '#d20000', '#d20000',
    '#d20000', '#d20000', '#d20000', '#d20000',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ea4335',
    '#ea4335', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#fbbc05', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#fbbc05', '#ffffff',
    '#4285f4', '#4285f4', '#ffffff', '#dddddd',
    '#ffffff', '#ffffff', '#ffffff', '#34a853',
    '#34a853', '#ffffff', '#ffffff', '#cccccc',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#dddddd', '#cccccc'
];

LED_ICON["calendar-microsoft"] = [
    '#f60000', '#f60000', '#f60000', '#f60000',
    '#f60000', '#f60000', '#f60000', '#f60000',
    '#d20000', '#d20000', '#d20000', '#d20000',
    '#d20000', '#d20000', '#d20000', '#d20000',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#f35325', '#f35325',
    '#81bc06', '#81bc06', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#f35325', '#f35325',
    '#81bc06', '#81bc06', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#05a6f0', '#05a6f0',
    '#ffba08', '#ffba08', '#ffffff', '#dddddd',
    '#ffffff', '#ffffff', '#05a6f0', '#05a6f0',
    '#ffba08', '#ffba08', '#ffffff', '#cccccc',
    '#ffffff', '#ffffff', '#ffffff', '#ffffff',
    '#ffffff', '#ffffff', '#dddddd', '#cccccc'
];

LED_ICON["scrollernote"] = [
    0,         '#87d2fb', '#87d2fb', '#87d2fb', '#87d2fb',
    '#87d2fb', '#87d2fb', 0,         '#87d2fb', '#49bdfe',
    '#67c8fe', '#67c8fe', '#67c8fe', '#67c8fe', '#67c8fe',
    '#00a2fc', '#87d2fb', '#49bdfe', '#49bdfe', '#67c8fe',
    '#67c8fe', '#67c8fe', '#67c8fe', '#00a2fc', '#87d2fb',
    '#49bdfe', '#49bdfe', '#49bdfe', '#49bdfe', '#67c8fe',
    '#67c8fe', '#00a2fc', '#87d2fb', '#49bdfe', '#49bdfe',
    '#49bdfe', '#49bdfe', '#49bdfe', '#49bdfe', '#00a2fc',
    0,         '#00a2fc', '#00a2fc', '#00a2fc', '#49bdfe',
    '#49bdfe', '#00a2fc', 0,         0,         0,
    0,         0,         '#49bdfe', '#00a2fc', 0,
    0,         0,         0,         0,         0,
    '#00a2fc', 0,         0,         0
  ];

LED_ICON["smile"] = [
    0,         0,         '#ffe000', '#ffe000', '#ffe000',
    '#ffe000', 0,         0,         0,         '#ffe000',
    '#ffe000', '#ffe000', '#ffe000', '#ffe000', '#ffe000',
    0,         '#333333', '#888888', '#666666', '#333333',
    '#888888', '#666666', '#333333', '#ffe000', '#333333',
    '#666666', '#444444', '#333333', '#666666', '#444444',
    '#333333', '#333333', '#ffe000', '#333333', '#333333',
    '#ffe000', '#333333', '#333333', '#ffe000', '#ffe000',
    '#ffe000', '#ffe000', '#ffe000', '#ffe000', '#ffe000',
    '#ffe000', '#ffe000', '#ffe000', 0,         '#ffe000',
    '#ffe000', 0,         0,         0,         '#ffe000',
    0,         0,         0,         '#ffe000', '#ffe000',
    '#ffe000', '#ffe000', 0,         0
];

LED_ICON["doge"] = [
    0,         '#f78818', '#865b26', 0,         0,
    '#f78818', '#865b26', 0,         0,         '#f78818',
    '#f78818', '#f78818', '#f78818', '#f78818', '#865b26',
    0,         '#f78818', '#f78818', '#f78818', '#f78818',
    '#f78818', '#f78818', '#f78818', '#f78818', '#f1e88a',
    '#ffffff', 0,         '#f78818', '#ffffff', 0,
    '#f78818', '#f78818', '#f1e88a', '#f1e88a', '#f78818',
    '#f78818', '#f78818', '#f1e88a', '#f1e88a', '#f78818',
    '#f1e88a', '#f1e88a', 0,         0,         '#f1e88a',
    '#f1e88a', '#f1e88a', '#f1e88a', '#f1e88a', '#f1e88a',
    '#444444', '#444444', '#f1e88a', '#f1e88a', '#f1e88a',
    '#f1e88a', 0,         '#f1e88a', '#f1e88a', '#f1e88a',
    '#f1e88a', '#f1e88a', '#f1e88a', '#f1e88a'
];

LED_ICON["xmastree"] = [
    0, 0, 0, '#f0f800',
    '#f0f800', 0, 0, 0,
    0, 0, 0, '#19b914',
    '#19b914', 0, 0, 0,
    0, 0, '#19b914', '#fc9c08',
    '#f800ba', '#19b914', 0, 0,
    0, '#19b914', '#f80500', '#19b914',
    '#19b914', '#19b914', '#19b914', 0,
    0, 0, '#19b914', '#0700f8',
    '#19b914', '#f80500', 0, 0,
    0, '#f800ba', '#19b914', '#19b914',
    '#e4f800', '#19b914', '#19b914', 0,
    '#e4f800', '#19b914', '#19b914', '#f80500',
    '#19b914', '#0700f8', '#19b914', '#fc9c08',
    0, 0, 0, '#d77300',
    '#d77300', 0, 0, 0
];

LED_ICON["heart"] = [
    0, '#ff0032', '#ff0032', 0, 0, '#ff0032', '#ff0032', 0,
    '#ffffff', '#ffffff', '#ff0032', '#ff0032', '#ff0032', '#ff0032', '#ff0032', '#ff0032',
    '#ffffff', '#ff0032', '#ff0032', '#ff0032', '#ff0032', '#ff0032', '#ff0032', '#ff0032',
    '#ff799b', '#ff0032', '#ff0032', '#ff0032', '#ff0032', '#ff0032', '#ff0032', '#ff0032',
    0, '#ff799b', '#ff0032', '#ff0032', '#ff0032', '#ff0032', '#ff0032', 0,
    0, 0, '#ff0032', '#ff0032', '#ff0032', '#ff0032', 0, 0,
    0, 0, 0, '#ff0032', '#ff0032', 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0
];

LED_ICON["coffee"] = [
    0, 0, '#ffffff', 0, 0, 0, 0, 0,
    0, 0, 0, '#f2f2f2', 0, '#ffffff', 0, 0,
    0, 0, '#e8e8e8', 0, '#f2f2f2', 0, 0, 0,
    0, '#ff4200', '#fd673a', '#ff2800', '#ff2800', '#ff4200', '#bf0303', 0,
    0, '#ff0000', '#fd673a', '#ff0000', '#ff0000', '#ff0000', 0, '#bf0303',
    0, '#ff0000', '#fd673a', '#ff0000', '#ff0000', '#ff0000', 0, '#bf0303',
    0, '#ff0000', '#ff0000', '#fd673a', '#ff0000', '#ff0000', '#bf0303', 0,
    0, 0, '#b70101', '#b70101', '#b70101', 0, 0, 0
];

LED_ICON["battery"] = [
    0, 0, 0, '#cccccc', '#cccccc', 0, 0, 0,
    0, 0, '#cccccc', '#cccccc', '#cccccc', '#cccccc', 0, 0,
    0, 0, '#cccccc', '#3fd42e', '#3fd42e', '#cccccc', 0, 0,
    0, 0, '#cccccc', '#22aa13', '#22aa13', '#cccccc', 0, 0,
    0, 0, '#cccccc', '#22aa13', '#22aa13', '#cccccc', 0, 0,
    0, 0, '#cccccc', '#22aa13', '#22aa13', '#cccccc', 0, 0,
    0, 0, '#cccccc', '#22aa13', '#22aa13', '#cccccc', 0, 0,
    0, 0, '#cccccc', '#cccccc', '#cccccc', '#cccccc', 0, 0
];

LED_ICON["like"] = [
    0, 0, 0, 0, '#ffffff', 0, 0, 0,
    0, 0, 0, '#ffffff', '#ffffff', 0, 0, 0,
    0, 0, 0, '#ffffff', '#ffffff', 0, 0, 0,
    '#0034ff', '#0034ff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', 0,
    '#0034ff', '#0034ff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', 0,
    '#0034ff', '#0034ff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', 0,
    '#0034ff', '#0034ff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', 0,
    '#0034ff', '#0034ff', 0, '#ffffff', '#ffffff', '#ffffff', 0, 0
];

LED_ICON["chatgpt"] = [
    0, 0, 0, '#ffffff', 0, 0, 0, 0,
    0, 0, 0, '#ffffff', 0, 0, 0, 0,
    0, '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', 0, 0,
    '#ffffff', 0, '#49bdfe', 0, '#49bdfe', 0, '#ffffff', 0,
    0, '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', 0, 0,
    0, 0, 0, '#cccccc', 0, 0, 0, 0,
    0, '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', 0, 0,
    0, '#ffffff', '#cccccc', '#cccccc', '#cccccc', '#ffffff', 0, 0,
];

LED_ICON["chatgpt-ask"] = [
    0, 0, 0, '#ffffff', 0, 0, 0, 0,
    0, 0, 0, '#ffffff', 0, 0, 0, 0,
    0, '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', 0, 0,
    '#ffffff', 0, '#49bdfe', 0, '#49bdfe', 0, '#ffffff', 0,
    0, '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', 0, 0,
    0, 0, 0, '#cccccc', 0, 0, 0, 0,
    0, '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', 0, '#ffffff',
    0, '#ffffff', '#cccccc', '#cccccc', '#cccccc', '#ffffff', '#ffffff', 0,
];

LED_ICON["meh-logo"] = [
    0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0,
    '#ffffff', '#ffffff', 0, '#ffffff', '#ffffff', 0, '#ffffff', '#ffffff',
    0, '#ffffff', '#ffffff', 0, '#ffffff', '#ffffff', 0, '#ffffff',
    0, '#ffffff', 0, 0, '#ffffff', 0, 0, '#ffffff',
    0, '#ffffff', 0, '#ffffff', '#ffffff', 0, '#ffffff', 0,
    '#ffffff', 0, 0, '#ffffff', 0, 0, '#ffffff', '#ffffff',
    0, 0, 0, 0, 0, 0, 0, 0,
];

LED_ICON["woot-logo"] = [
    0, 0, "#4b7c43", "#4b7c43", "#4b7c43", "#4b7c43", 0, 0,
    0, "#4b7c43", "#4b7c43", "#ffffff", "#ffffff", "#4b7c43", "#4b7c43", 0,
    "#4b7c43", "#4b7c43", "#4b7c43", "#ffffff", "#ffffff", "#4b7c43", "#4b7c43", "#4b7c43",
    "#4b7c43", "#4b7c43", "#4b7c43", "#ffffff", "#ffffff", "#4b7c43", "#4b7c43", "#4b7c43",
    "#4b7c43", "#4b7c43", "#4b7c43", "#4b7c43", "#4b7c43", "#4b7c43", "#4b7c43", "#4b7c43",
    "#4b7c43", "#4b7c43", "#4b7c43", "#ffffff", "#ffffff", "#4b7c43", "#4b7c43", "#4b7c43",
    0, "#4b7c43", "#4b7c43", "#ffffff", "#ffffff", "#4b7c43", "#4b7c43", 0,
    0, 0, "#4b7c43", "#4b7c43", "#4b7c43", "#4b7c43", 0, 0,
];

LED_ICON["woot-off"] = [
    0, 0, "#4b7c43", "#4b7c43", "#4b7c43", "#4b7c43", 0, 0,
    0, "#4b7c43", "#ffb91b", "#ffd01b", "#ffe71b", "#ffd01b", "#4b7c43", 0,
    "#4b7c43", "#4b7c43", "#ffb91b", "#ffd01b", "#ffe71b", "#ffd01b", "#4b7c43", "#4b7c43",
    "#4b7c43", "#4b7c43", "#ffb91b", "#ffd01b", "#ffe71b", "#ffd01b", "#4b7c43", "#4b7c43",
    "#4b7c43", "#4b7c43", "#ffb91b", "#ffb91b", "#ffb91b", "#ffb91b", "#4b7c43", "#4b7c43",
    "#4b7c43", "#4b7c43", 0, "#444444", "#444444", "#444444", "#4b7c43", "#4b7c43",
    0, "#4b7c43", 0, 0, 0, 0, "#4b7c43", 0,
    0, 0, "#4b7c43", "#4b7c43", "#4b7c43", "#4b7c43", 0, 0,
];

export default LED_ICON;



// async function getIcon(id) { return await require('axios').get(`https://developer.lametric.com/api/v1/dev/preloadicons?icon_id=${id}`).then((res) => JSON.parse(res.data.body).icons); }
// function toHex([r, g, b, a]) { if (!a) { return 0; } r=Math.round(r*255).toString(16).padStart(2, '0'); g=Math.round(g*255).toString(16).padStart(2, '0'); b=Math.round(b*255).toString(16).padStart(2, '0'); return `#${r}${g}${b}`; }
// var arr = await getIcon(12147)
// arr[0].map((row) => row.map((el) => toHex(el))).flat()
// arr[arr.length-1].map((row) => row.map((el) => toHex(el))).flat()
