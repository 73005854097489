import './App.css';
import 'bulma/css/bulma.min.css';
import '@creativebulma/bulma-divider/dist/bulma-divider.min.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Home from './components/Home';
import BuyMeCoffee from './components/BuyMeCoffee';
import Applications from './components/apps/Applications';
import LaMetricApp from './components/apps/LaMetricApp';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RouteChangeTracker from './components/utils/RouteChangeTracker';

function App() {
    return (
        <Router>
            <div className="App light-bg">
                <Navbar />
                <main>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="applications" element={<Applications />}>
                            <Route path=":appSlug" element={<LaMetricApp />} />
                        </Route>
                        <Route path="bmcthx" element={<BuyMeCoffee />} />
                    </Routes>
                </main>
                <Footer />
            </div>
            <RouteChangeTracker />
        </Router>
    );
}

export default App;
