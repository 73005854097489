import { NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";

import logo from "../resources/scrollernote-logo-xs.png";
import bmc from "../resources/bmc-logo.svg";

function Navbar () {
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    useEffect(() => {
      const el = document.activeElement;

      el?.classList?.contains("navbar-item") && el.blur();
      window.scrollTo(0, 0);

    }, [location.pathname]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const closeMenu = () => {
        setMenuOpen(false);
    }

    const sayThanks = () => {
        setTimeout(() => {
            window.location.href = "/bmcthx";
        }, 500);
    }

    return (
        <nav className="navbar is-fixed-top is-primary" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <NavLink className="navbar-item" to="/">
                    <img src={logo} alt="Scroller Note logo" />
                </NavLink>

                <button className={`navbar-burger ${menuOpen ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"
                    onClick={toggleMenu}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </button>
            </div>

            <div id="navbarBasicExample" className={`navbar-menu ${menuOpen ? "is-active" : ""}`}>
                <div className="navbar-start">

                    <NavLink className="navbar-item has-text-dark" to="applications/note" onClick={closeMenu}>
                        Scroller Note
                    </NavLink>
                    <NavLink className="navbar-item has-text-dark" to="applications/fitness" onClick={closeMenu}>
                        Fitness Tracker
                    </NavLink>
                    <NavLink className="navbar-item has-text-dark" to="applications/calendar" onClick={closeMenu}>
                        Calendar Viewer
                    </NavLink>
                    <NavLink className="navbar-item has-text-dark" to="applications/inspire" onClick={closeMenu}>
                        Inspire GPT
                    </NavLink>
                    <NavLink className="navbar-item has-text-dark" to="applications/joke" onClick={closeMenu}>
                        Joke GPT
                    </NavLink>
                    <NavLink className="navbar-item has-text-dark" to="applications/deepthoughts" onClick={closeMenu}>
                        Deep Thoughts GPT
                    </NavLink>
                    <NavLink className="navbar-item has-text-dark" to="applications/woot" onClick={closeMenu}>
                        Woot! Daily Deals
                    </NavLink>
                    <NavLink className="navbar-item has-text-dark" to="applications/meh" onClick={closeMenu}>
                        Meh Daily Deals
                    </NavLink>
                </div>
                <div className="navbar-end">
                    <ReactGA.OutboundLink
                        className="navbar-item has-text-dark"
                        eventLabel="BMC-header"
                        to="https://www.buymeacoffee.com/mbielich"
                        target="_blank"
                        onClick={sayThanks}>
                        <img src={bmc} width="36px" height="36px" className="bmc-logo" alt="Like my apps?  Consider buying me a coffee to fuel my work!" />
                        <span className="bmc-menu-text">Buy me a coffee?</span>
                    </ReactGA.OutboundLink>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
