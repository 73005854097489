import React from "react";
import DataTransparency from "./DataTransparency";
import FAQ from "./FAQ";
import KnownIssues from "./KnownIssues";
import ReactGA from "react-ga";

class DetailsTabs extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            slug: null,
            tabs: [],
            selectedTab: 0
        };
    
        this.selectTab = this.selectTab.bind(this);
    }

    setupTabs() {
        let slug = this.props.data.slug;
        let tabs = [];
        if (!!this.props.data?.dataTransparency) {
            tabs.push({
                name: "Data Transparency",
                shortName: "Data",
                icon: "fas fa-user-shield",
                content: (<DataTransparency data={this.props.data.dataTransparency} />)
            });
        }
        if (this.props.data?.faq?.length > 0) {
            tabs.push({
                name: "Frequently Asked Questions",
                shortName: "FAQ",
                icon: "fas fa-question-circle",
                content: (<FAQ data={this.props.data.faq} />)
            });
        }
        if (!!this.props.data?.issues) {
            tabs.push({
                name: "Known Issues",
                shortName: "Issues",
                icon: "fas fa-bug",
                content: (<KnownIssues tag={this.props.data.issues} />)
            });
        }

        let selectedTab = Math.max(0, tabs.findIndex(({ shortName }) => this.props.tabSlug === shortName));
        this.setState({
            slug,
            tabs,
            selectedTab
        });
    }

    trackTab() {
        if (this.state.tabs.length <= 0 || this.state.selectedTab >= this.state.tabs.length) {
            return;
        }
        ReactGA.event({
            category: 'Navigation',
            action: 'TabViewed',
            label: this.state.tabs[this.state.selectedTab].name
        });
    }

    componentDidMount() {
        if (this.state.slug !== this.props.data.slug) {
            this.setupTabs();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.slug !== this.props.data.slug) {
            this.setupTabs();
        }
        if (this.state.tabs?.length > 0 && (prevState.tabs?.length <= 0 || prevState.selectedTab !== this.state.selectedTab)) {
            this.trackTab();
        }
    }

    selectTab(event) {
        let index = parseInt(event.currentTarget.id);
        this.setState({ selectedTab: index });
    }

    render() {
        return (
            <div>
                <div className="tabs is-centered is-boxed mb-0">
                    <ul>
                        { this.state.tabs.map((tab, index) => (
                            <li key={`${this.props.data.slug}-tab-${index}`} className={`${index === this.state.selectedTab ? 'is-active' : ''}`}>
                                <a id={index} onClick={this.selectTab} href={`#${tab.shortName}`}>
                                    <span className="icon is-small"><i className={tab.icon} aria-hidden="true"></i></span>
                                    { !!tab.shortName ? (
                                        <span>
                                            <span className="is-hidden-mobile">{tab.name}</span>
                                            <span className="is-hidden-tablet">{tab.shortName}</span>
                                        </span>
                                    ) : (
                                        <span>{tab.name}</span>
                                    ) }
                                </a>
                            </li>
                        )) }
                    </ul>
                </div>
                {this.state.tabs.length > 0 && this.state.selectedTab < this.state.tabs.length && this.state.tabs[this.state.selectedTab].content}
            </div>
        );
    }
}

export default DetailsTabs;
